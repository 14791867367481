/*import "/src/styles/bootstrap.min.css"
import "/src/styles/override.css"
import "/src/lib/jquery-2.2.4.js"
import "/src/lib/bootstrap-3.0.2.min.js"*/

import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

import "/src/styles/override.css"
import "/src/styles/carousel.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import "/plugins/img-comparison-slider.js"
import "/plugins/img-comparison-slider.css"

import "/plugins/gatsby-remark-span/gatsby-remark-span.css"

import React from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import i18n from 'i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    languages: ['en', 'fr'],
    defaultLanguage: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });


export const onRouteUpdate = ({ location }) => {
  if (location) {
    const isFrench = location.pathname.includes('/fr/');
    document.documentElement.lang = isFrench ? 'fr' : 'en';
  }
};

export const shouldUpdateScroll = ({ routerProps }) => {
  return [0, 0]; // Force le scroll en haut de la page à chaque changement de route
};

export const onClientEntry = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://scripts.simpleanalyticscdn.com/latest.js";
  document.body.appendChild(script);
};